import withRoot from "../../utils/withRoot";
import React from "react";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import Title from '../../components/Title'
import Para from '../../components/Para'
import TextListItem from '../../components/TextListItem'
import {Link} from 'gatsby'
import CtaButton from '../../components/CtaButton'

const NoCodeBenefits = props => {
    return (
        <Page title="How bespoke Low Code software can help you">
            <SEO title="How bespoke Low Code software can help you"/>

            <Para>
                Whatever sort of organisation you work in, Low Code offers lots of possibilities.
            </Para>

            <Title level='h3'>Save time, money and stress with a custom system</Title>
            <Para>Are you and your staff struggling to work efficiently because no package software supports your unique
                needs? You need a bespoke system, but maybe your organisation has no development expertise, or the IT
                department is too busy to help you, and outside suppliers cost too much. Low Code could let you get the
                software you need to do your work efficiently, at a price you can afford.
            </Para>

            <Title level='h3'>Give your existing software a boost</Title>
            <Para>Sometimes a standard system gives you most of what you need, but that last 20% would make all the
                difference to the way you work. An add-on system built with Low Code could fill in the gaps and connect
                with your existing system, so you get the best of both worlds.
            </Para>

            <Title level='h3'>Take advantage of new business opportunities</Title>
            <Para>If you are starting a new business, or a new venture in an existing business, you probably need a
                custom app to make it work. Low Code can help you build an initial product in record time, and then give you the flexibility
                to extend and change it as you learn more about what you need.
            </Para>

            <Title level='h3'>Reduce dependence on outside suppliers</Title>
            <Para>Maybe a bespoke software company has built a system for you. That process can be expensive,
                full of communication problems and leave you dependent on that supplier. With Low Code, you could build
                your own system, save money, change it when you want and have full control.
            </Para>


            <Title>What is Low Code?</Title>
            <Para>
                Low Code tools let you do programming without coding.
                You design the program mainly by drawing diagrams and filling in forms, and the closest you get to
                coding is creating simple formulas, similar to those in a spreadsheet.
            </Para>

            <Title level='h3'>Who is it for?</Title>
            <Para>It is aimed at people who are not technical specialists, and need to get a new system built as part
                of their work - often called “citizen developers”.
            </Para>

            <Title level='h3'>How long does it take to learn?</Title>
            <Para>
                Everyone is different, of course, but someone without previous programming experience could typically get started with a Low Code tool in a
                couple of hours,
                produce their first simple program in a day and be fairly proficient in a week.
                That’s about as difficult as learning to drive a car, whereas conventional coding takes about as long as
                learning to fly an airliner - around a year.
            </Para>

            <Title level='h3'>What can it do?</Title>
            <Para>
                Low Code tools are powerful enough to build fully-featured business systems.
                And if you need something they can’t do, it is usually possible to have a specialist build an add-on
                that you can plug into your system.
            </Para>

            <Title level='h3'>Is Low Code the same as No-code?</Title>
            <Para>
                No-code tools are similar, they may be slightly easier to use, but they often are more limited in what they can do.
            </Para>

            <Title level='h3'>What Low Code tools do you use?</Title>
            <Para>
                We specialise in <a href='https://elemento.software' target='_blank' rel="noopener noreferrer">Elemento</a>, which we
                have developed ourselves to overcome the disadvantages of other Low Code tools.
                It is easy to learn, free to use, and can be used for both internal systems and public websites.
                There is no lock-in - you control your software and data, and you can run it at low cost on standard cloud hosting services.
            </Para>


            <Title>Effective Low Code development</Title>

            <Para>Programming with Low Code tools is very quick. but there are many other things involved in developing
                good software. You still need to:
            </Para>
            <ul>
                <TextListItem>find out what your business needs the system to do</TextListItem>
                <TextListItem>design a solution</TextListItem>
                <TextListItem>test the system (because people still make mistakes, even with the best tools)</TextListItem>
                <TextListItem>set priorities and manage the work</TextListItem>
            </ul>
            <Para>Conventional IT practice has come up with methods and ways of working to solve all these problems. But
                they tend to be heavyweight and designed for situations where the programming takes much longer than the
                other parts.
                Low Code tools let everything happen much faster, so they need a new lightweight method, designed to take
                full advantage of them. That’s why we have designed our own method - <Link to='/working#hand'>Highly Agile Low Code (HALC)</Link>.
            </Para>

            <Para align='center'><CtaButton href='/contact'>Tell us about your problems</CtaButton></Para>
            <Para align='center'><CtaButton href='/working' variant='outlined'>See how we work with you</CtaButton></Para>
        </Page>
    );
};

export default withRoot(NoCodeBenefits);
